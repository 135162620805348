import { gql, useMutation, useQuery } from '@apollo/client';
import * as React from 'react';

import {
  CreateSectionInput,
  MoveShortcutInput,
  NavShortcutType,
  UpdateSectionInput,
} from '../../___generated___/globalTypes';
import Logger from '../../lib/observability/Logger';
import { ProfileAvatar } from '../Avatar';
import LiveAvatar from '../PresenceBar/LiveAvatar';

import {
  AddShortcut,
  AddShortcutVariables,
  CreateSection,
  CreateSectionVariables,
  DeleteShortcut,
  DeleteShortcutVariables,
  HF_NavBarShortcut,
  MoveShortcut,
  MoveShortcutVariables,
  NavConfig,
  NavConfigVariables,
  UpdateSection,
  UpdateSectionVariables,
} from './___generated___/hooks.types';

export const ShortcutTargetFragment = gql`
  fragment HF_ShortcutTarget on INode {
    id
    ... on Account {
      id
      permalink
      privateChat {
        id
        hasUnread
        meeting {
          id
        }
        newRepliesCount
      }
      ...CF_LiveAvatar
    }

    ... on Discussion {
      name
      hasUnread
      meeting {
        id
      }
      newRepliesCount
      permalink
      subject
      ...CF_ProfileAvatar
    }

    ... on PrivateChat {
      name
      hasUnread
      meeting {
        id
      }
      newRepliesCount
      participants {
        id
        ...CF_LiveAvatar
      }
      permalink
      ...CF_ProfileAvatar
    }

    ... on Team {
      name
      hasUnread
      meeting {
        id
      }
      newRepliesCount
      permalink
      ...CF_ProfileAvatar
    }
  }
  ${ProfileAvatar.fragment}
  ${LiveAvatar.fragment}
`;

export const NavBarShortcutFragment = gql`
  fragment HF_NavBarShortcut on NavShortcut {
    id
    sectionId
    target {
      ...HF_ShortcutTarget
    }
  }
  ${ShortcutTargetFragment}
`;

export const NavBarSectionFragment = gql`
  fragment HF_NavBarSection on NavSection {
    id
    name
    shortcuts {
      id
      ...HF_NavBarShortcut
    }
  }
  ${NavBarShortcutFragment}
`;

const NavConfigQuery = gql`
  fragment HF_NavConfigSection on NavSection {
    id
    name
    shortcuts {
      id
      target {
        ... on INode {
          id
        }
      }
      sectionId
    }
  }

  query NavConfig {
    account {
      id
      navConfig {
        id
        defaultSection {
          id
          ...HF_NavConfigSection
        }
        sections {
          id
          ...HF_NavConfigSection
        }
      }
    }
  }
`;

export function useNavConfigQuery() {
  return useQuery<NavConfig, NavConfigVariables>(NavConfigQuery);
}

export interface IShortcut {
  id: string;
  sectionId: string;
  target: IShortcutTarget;
}

export function useGetShortcutForTarget(targetId?: string): IShortcut | undefined {
  const { data } = useNavConfigQuery();
  const sections = data?.account ? data.account.navConfig.sections : [];
  const shortcuts = sections.map(({ shortcuts }) => shortcuts).flat();
  return shortcuts.find(({ target }) => targetId === target.id);
}

const AddShortcutMutation = gql`
  mutation AddShortcut($input: AddShortcutInput) {
    addShortcut(input: $input) {
      id
      defaultSection {
        ...HF_NavBarSection
      }
      sections {
        ...HF_NavBarSection
      }
    }
  }
  ${NavBarSectionFragment}
`;

const DeleteShortcutMutation = gql`
  mutation DeleteShortcut($input: DeleteShortcutInput) {
    deleteShortcut(input: $input) {
      id
      defaultSection {
        ...HF_NavBarSection
      }
      sections {
        ...HF_NavBarSection
      }
    }
  }
  ${NavBarSectionFragment}
`;

export interface IShortcutTarget {
  id: string;
  __typename: 'Account' | 'Discussion' | 'PrivateChat' | 'Team';
}

function getShortcutType(target: IShortcutTarget) {
  switch (target.__typename) {
    case 'Account':
      return NavShortcutType.ACCOUNT;
    case 'PrivateChat':
      return NavShortcutType.PRIVATE_CHAT;
    case 'Discussion':
    default:
      return NavShortcutType.DISCUSSION;
  }
}

export function useAddShortcutMutation() {
  const [_addShortcut, result] = useMutation<AddShortcut, AddShortcutVariables>(AddShortcutMutation);
  const [resolve, setResolver] = React.useState<[string, (value: HF_NavBarShortcut) => void]>();

  /**
   * addShortcut awaits the result of the mutation and returns the created shortcut
   */
  const addShortcut = React.useCallback(
    async (target?: IShortcutTarget, sectionId?: string, position?: number) => {
      if (!target) {
        return;
      }

      try {
        await _addShortcut({
          variables: {
            input: {
              position,
              sectionId,
              shortcutType: getShortcutType(target),
              targetId: target.id,
            },
          },
        });

        return new Promise((resolve: (value: HF_NavBarShortcut) => void) => {
          setResolver([target.id, resolve]);
        });
      } catch (err) {
        Logger.error(err as Error, 'Could not add shortcut');
        return Promise.reject(err);
      }
    },
    [_addShortcut]
  );

  React.useEffect(() => {
    if (!resolve) {
      return;
    }
    if (result.data?.addShortcut) {
      const navConfig = result.data?.addShortcut;
      const [targetId, _resolve] = resolve;
      // find newly created shortcut for target
      const shortcut = [navConfig.defaultSection.shortcuts, ...navConfig.sections.map((section) => section.shortcuts)]
        .flat()
        .find((s) => s.target.id === targetId);
      if (shortcut) {
        _resolve(shortcut);
      }
      setResolver(undefined);
    }
  }, [resolve, result]);

  return [addShortcut, result] as const;
}

export function useDeleteShortcutMutation() {
  const [_deleteShortcut, result] = useMutation<DeleteShortcut, DeleteShortcutVariables>(DeleteShortcutMutation);

  async function deleteShortcut(shortcutId: string) {
    try {
      await _deleteShortcut({
        variables: { input: { shortcutId } },
      });
    } catch (err) {
      Logger.error(err as Error, 'Could not delete shortcut');
    }
  }

  return [deleteShortcut, result] as const;
}

const MoveShortcutMutation = gql`
  mutation MoveShortcut($input: MoveShortcutInput) {
    moveShortcut(input: $input) {
      id
      defaultSection {
        ...HF_NavBarSection
      }
      sections {
        ...HF_NavBarSection
      }
    }
  }
  ${NavBarSectionFragment}
`;

export function useMoveShortcutMutation() {
  const [_moveShortcut, result] = useMutation<MoveShortcut, MoveShortcutVariables>(MoveShortcutMutation);

  async function moveShortcut(input: MoveShortcutInput) {
    try {
      await _moveShortcut({
        variables: { input },
      });
    } catch (err) {
      Logger.error(err as Error, 'Could not move shortcut');
    }
  }

  return [moveShortcut, result] as const;
}

const CreateSectionMutation = gql`
  mutation CreateSection($input: CreateSectionInput) {
    createSection(input: $input) {
      id
      defaultSection {
        id
        name
      }
      sections {
        id
        name
      }
    }
  }
`;

export function useCreateSectionMutation() {
  const [_createSection, result] = useMutation<CreateSection, CreateSectionVariables>(CreateSectionMutation);

  async function createSection(input: CreateSectionInput) {
    try {
      await _createSection({
        variables: { input },
      });
    } catch (err) {
      Logger.error(err as Error, 'Could not update section');
    }
  }

  return [createSection, result] as const;
}

const DeleteSectionMutation = gql`
  mutation DeleteSection($input: DeleteSectionInput) {
    deleteSection(input: $input) {
      id
      defaultSection {
        id
      }
      sections {
        id
      }
    }
  }
`;

export function useDeleteSectionMutation() {
  const [_deleteSection, result] = useMutation(DeleteSectionMutation);

  async function deleteSection(sectionId: string) {
    try {
      await _deleteSection({
        variables: { input: { sectionId } },
      });
    } catch (err) {
      Logger.error(err as Error, 'Could not update section');
    }
  }

  return [deleteSection, result] as const;
}

const UpdateSectionMutation = gql`
  mutation UpdateSection($input: UpdateSectionInput) {
    updateSection(input: $input) {
      id
      defaultSection {
        id
        name
      }
      sections {
        id
        name
      }
    }
  }
`;

export function useUpdateSectionMutation() {
  const [_updateSection, result] = useMutation<UpdateSection, UpdateSectionVariables>(UpdateSectionMutation);

  async function updateSection(input: UpdateSectionInput) {
    try {
      await _updateSection({
        variables: { input },
      });
    } catch (err) {
      Logger.error(err as Error, 'Could not update section');
    }
  }

  return [updateSection, result] as const;
}

import { C_DESKTOP_OIDC_CLIENT_ID, C_WEBAPP_OIDC_CLIENT_ID, Paths } from '@rmvw/x-common';
import * as React from 'react';

import ErrorBoundary from '../../components/ErrorBoundary';
import { IS_DESKTOP_APP } from '../../env';
import useThemePreference from '../../hooks/useThemePreference';
import ApolloProvider from '../../providers/ApolloProvider';
import AuthProvider from '../../providers/AuthProvider';
import ThemeProvider from '../../providers/ThemeProvider';
import YDocTransportProvider from '../../providers/YDocTransportProvider';
import SubscriptionHandler from '../../SubscriptionHandler';

const REDIRECT_BASE_URL = process.env.OAUTH2_CLIENT_REDIRECT_BASE_URL ?? '';

/**
 * Implements provider stack common to most entrypoints
 */
export default function CommonEntrypointProviders(props: React.PropsWithChildren<unknown>) {
  const { themeName } = useThemePreference();
  return (
    <ThemeProvider theme={themeName}>
      <ErrorBoundary>
        <AuthProvider
          clientId={IS_DESKTOP_APP ? C_DESKTOP_OIDC_CLIENT_ID : C_WEBAPP_OIDC_CLIENT_ID}
          issuer={`${process.env.S_API_BASE_URL}/oidc`}
          redirectUri={
            IS_DESKTOP_APP ? `${REDIRECT_BASE_URL}${Paths.LOGIN_FOR_DESKTOP()}` : `${REDIRECT_BASE_URL}${Paths.LOGIN}`
          }
        >
          <YDocTransportProvider>
            <ApolloProvider>
              <SubscriptionHandler />
              {props.children}
            </ApolloProvider>
          </YDocTransportProvider>
        </AuthProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

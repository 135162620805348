import * as React from 'react';
import styled from 'styled-components';

import { truncateText } from '../../lib/css';
import MaximizeThreadControl from '../meeting/controls/MaximizeThreadControl';
import Popover, { BasePopover } from '../Popover';
import Space from '../ui/Space';

export const HOVER_CARD_AVATAR_SIZE = 96;

export const _Popover = styled(BasePopover)`
  border: 1px solid ${({ theme }) => theme.color.border};
  transition: border-color 0.5s;

  &:hover {
    border-color: ${({ theme }) => theme.color.secondaryBorder};
  }
`;

const _Profile = styled.div`
  display: flex;
  padding: 12px;
`;

const _Details = styled.div`
  color: ${({ theme }) => theme.color.color};
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 0 0 0 8px;
`;

const _Name = styled.div`
  color: ${({ theme }) => theme.color.color};
  flex: 1;
  font-size: ${({ theme }) => theme.fontSize.h5};
  ${truncateText()}
`;

export type HoverCardProps = React.PropsWithChildren<{
  avatar: React.ReactNode;
  chatPermalink?: string;
  content?: React.ReactNode;
  name: string;
}>;

export function HoverCard(props: HoverCardProps) {
  const content = (
    <_Profile>
      {props.avatar}
      <_Details>
        <Space>
          <_Name>{props.name}</_Name>
          {props.chatPermalink && <MaximizeThreadControl path={props.chatPermalink} size="small" />}
        </Space>
        {props.content}
      </_Details>
    </_Profile>
  );

  const popover = <_Popover>{content}</_Popover>;

  return (
    <Popover overlay={popover} trigger="click">
      <a href="#" onClick={(e) => e.preventDefault()} role="button">
        {props.children}
      </a>
    </Popover>
  );
}

export type HoverCardGridRow = React.PropsWithChildren<{
  icon: React.ReactNode;
  title?: string;
}>;

/////

const _GridContainer = styled.div`
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.xSmall};
`;

const _GridIcon = styled.div`
  display: flex;
  margin: 0 4px 0 0;
`;

const _GridContent = styled.div`
  flex: 1;
  font-size: ${({ theme }) => theme.fontSize.xSmall};
`;

export function HoverCardGridRow(props: HoverCardGridRow) {
  return (
    <_GridContainer>
      <_GridIcon title={props.title}>{props.icon}</_GridIcon>
      <_GridContent>{props.children}</_GridContent>
    </_GridContainer>
  );
}

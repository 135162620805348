import { ApolloError, gql, useApolloClient, useFragment_experimental, useLazyQuery, useQuery } from '@apollo/client';
import * as React from 'react';

import Logger from '../../../lib/observability/Logger';
import { ProfileAvatar } from '../../Avatar';
import MeetingPreview from '../../meeting/MeetingPreview';
import PaneHeader from '../../PaneHeader';

import {
  GetDiscussionPreview,
  GetDiscussionPreviewVariables,
  GetDiscussionPreviewViewer,
  GetDiscussionPreviewViewerVariables,
  HF_DiscussionPreview,
} from './___generated___/useDiscussionPreviewQuery.types';

export const DiscussionPreviewFragment = gql`
  ${MeetingPreview.fragment}
  ${ProfileAvatar.fragment}
  ${PaneHeader.ThreadContext.fragment}
  fragment HF_DiscussionPreview on IThread {
    __typename
    id
    abstract
    firstMessage {
      id
      body
    }
    lastMeeting {
      id
      isActive
      permalink
      readmeNote {
        body
        isEmpty
        name
      }
      ...CF_ThreadMeetingPreview
    }
    parent {
      id
      name
      permalink
    }
    participants {
      id
    }
    permalink
    ... on Discussion {
      id
      subject
      org {
        name
      }
    }
    messageCount
    unreadMessageCount
    readmeNote {
      body
    }
    videoCount
    ...CF_ProfileAvatar
    ...CF_PaneHeaderThreadContext
  }
`;

export const DiscussionPreviewQuery = gql`
  ${MeetingPreview.fragment}
  ${ProfileAvatar.fragment}
  ${DiscussionPreviewFragment}

  query GetDiscussionPreview($threadId: ID!) {
    thread(id: $threadId) {
      ...HF_DiscussionPreview
    }
  }
`;

export const DiscussionPreviewViewerQuery = gql`
  ${PaneHeader.ThreadContext.viewerFragment}
  query GetDiscussionPreviewViewer {
    account {
      ...CF_PaneHeaderThreadContextViewer
    }
  }
`;

function useDiscussionPreviewQuery(threadId: string) {
  const { data: viewerData } = useQuery<GetDiscussionPreviewViewer, GetDiscussionPreviewViewerVariables>(
    DiscussionPreviewViewerQuery
  );
  const [lazyLoad, { called, loading, data, error }] = useLazyQuery<
    GetDiscussionPreview,
    GetDiscussionPreviewVariables
  >(DiscussionPreviewQuery, {
    variables: { threadId },
  });
  const apolloClient = useApolloClient();
  const from = apolloClient.cache.identify({ __typename: 'Discussion', id: threadId }) || '';
  const fragmentData = useFragment_experimental<HF_DiscussionPreview, unknown>({
    from,
    fragment: DiscussionPreviewFragment,
    fragmentName: 'HF_DiscussionPreview',
  });
  const fragmentResult = React.useMemo(
    () => ({
      data: fragmentData.data && { ...viewerData, thread: fragmentData.data },
      loading: !fragmentData.complete,
      error: undefined as ApolloError | undefined,
    }),
    [fragmentData, viewerData]
  );
  if (fragmentData.complete) {
    return fragmentResult;
  }
  if (!called) {
    lazyLoad().catch((e) => Logger.error(e, '[useDiscussionPreviewQuery] Failed to load discussion preview'));
    return { data: undefined, error: undefined, loading: true };
  }
  return { data: { ...viewerData, ...data }, error, loading };
}

export default useDiscussionPreviewQuery;

import { gql, useQuery } from '@apollo/client';
import { LegacyPalette } from '@rmvw/c-common';
import { RTAccountRefElement, RTElementProp } from '@rmvw/x-common';
import * as React from 'react';
import { useSelected } from 'slate-react';
import styled from 'styled-components';

import { usePermissionScope } from '../../../../../providers/PermissionScopeProvider';
import AccountHoverCard from '../../../../HoverCard/AccountHoverCard';
import AtMentionPill from '../../../../ui/Pills/AtMentionPill';
import { IRTElementProps } from '../../../IRTElementProps';
import VoidInlineElement from '../../common/VoidInlineElement';

import {
  BATCHED__AccountRefElement_AccountQuery,
  BATCHED__AccountRefElement_AccountQueryVariables,
} from './___generated___/AccountRefElement.types';

const _AccountRef = styled(AtMentionPill)<{ $canSee?: boolean }>`
  color: ${({ $canSee }) => ($canSee ? undefined : LegacyPalette.red60)};
`;

export interface IBaseAccountRefElementProps extends IRTElementProps<RTAccountRefElement> {
  canSee?: boolean;
  name: string;
  selected?: boolean;
}

export function BaseAccountRefElement(props: IBaseAccountRefElementProps) {
  return (
    <VoidInlineElement attributes={props.attributes} slateChildren={props.children}>
      <AccountHoverCard id={props.element[RTElementProp.ACCOUNT_REF__ACCOUNT_ID] ?? ''}>
        <_AccountRef $canSee={props.canSee} selected={props.selected}>
          {props.name}
        </_AccountRef>
      </AccountHoverCard>
    </VoidInlineElement>
  );
}

const AccountQuery = gql`
  # Batched query
  query BATCHED__AccountRefElement_AccountQuery($id: ID!, $targetId: ID) {
    account(id: $id) {
      id
      name
      canSee(id: $targetId)
    }
  }
`;

export default function AccountRefElement(props: IRTElementProps<RTAccountRefElement>) {
  const scope = usePermissionScope();
  const selected = useSelected();
  const accountQuery = useQuery<
    BATCHED__AccountRefElement_AccountQuery,
    BATCHED__AccountRefElement_AccountQueryVariables
  >(AccountQuery, {
    variables: { id: props.element[RTElementProp.ACCOUNT_REF__ACCOUNT_ID] ?? '', targetId: scope?.id },
    skip: !props.element[RTElementProp.ACCOUNT_REF__ACCOUNT_ID],
  });

  const canSee = !(accountQuery.data?.account?.canSee === false);

  return (
    <BaseAccountRefElement
      {...props}
      canSee={canSee}
      name={accountQuery.data?.account?.name ?? '...'}
      selected={selected}
    />
  );
}

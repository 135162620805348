import { Emoji } from 'emoji-picker-react';
import * as React from 'react';
import styled from 'styled-components';

import { BASE_EMOJI_PICKER_DEFAULT_EMOJI_STYLE } from './BaseEmojiPicker';

const _Container = styled.span`
  img {
    display: inline-block; /* Make sure this doesn't get overridden by emoji-picker-react */
  }
`;

interface IBaseEmojiProps {
  size?: number;
  unified: string;
}

function BaseEmoji({ size: _size, unified }: IBaseEmojiProps) {
  const [size, setSize] = React.useState(_size ?? 0);
  const ref = React.useRef<HTMLSpanElement>(null);

  React.useEffect(() => {
    // Hacky way to compute to correct font size in px for the emoji component (which does not support em/rem)
    if (!_size && ref.current) {
      setSize(parseFloat(window.getComputedStyle(ref.current).fontSize));
    }
  }, [_size]);

  return (
    <_Container ref={ref}>
      {size && <Emoji emojiStyle={BASE_EMOJI_PICKER_DEFAULT_EMOJI_STYLE} size={size} unified={unified} />}
    </_Container>
  );
}

export default BaseEmoji;

import { gql } from '@apollo/client';
import { exhaustive } from '@rmvw/x-common';
import * as React from 'react';
import styled from 'styled-components';

import { AudienceType } from '../../../___generated___/globalTypes';
import { HQ_useLoggedInAccount } from '../../../hooks/___generated___/useLoggedInAccount.types';
import { useLoggedInAccount } from '../../../hooks/useLoggedInAccount';
import { truncateText } from '../../../lib/css';
import Logger from '../../../lib/observability/Logger';
import { getAudienceOptions } from '../../audienceControls/getAudienceOptions';
import ExternalBadge from '../../ExternalBadge';
import Tooltip from '../../Tooltip';
import ThreadOriginBreadcrumbs from '../ThreadOriginBreadcrumbs';

import {
  CF_PaneHeaderThreadContextViewer,
  CF_ThreadMembershipContext,
  CF_ThreadMembershipContext_Discussion,
} from './___generated___/PaneHeaderThreadContext.types';

const _ContextList = styled.ul`
  color: ${({ theme }) => theme.color.secondaryColor};
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.xSmall};
  gap: 4px;
  margin: 0;
  padding: 0;
`;

const _ContextItem = styled.li`
  align-items: center;
  display: flex;
  list-style: none;
`;

const _Audience = styled.span`
  align-items: center;
  border-radius: 100%;
  color: ${({ theme }) => theme.color.color};
  cursor: default;
  display: inline-flex;
  height: 20px;
  justify-content: center;
  width: 20px;
`;

const _Tooltip = styled.div`
  text-align: left;
`;

const _TooltipName = styled.div`
  ${truncateText()}
`;

interface IMember {
  id: string;
  name: string;
  org: {
    id: string;
    __typename: 'Org';
  } | null;
}

function _Member({ account, id, name }: IMember & { account: HQ_useLoggedInAccount['account'] }) {
  return <_TooltipName>{account?.id === id ? 'You' : name}</_TooltipName>;
}

function _getThreadContext(thread: CF_ThreadMembershipContext): {
  audience: AudienceType;
  members: IMember[];
  org: CF_ThreadMembershipContext_Discussion['org'];
} {
  let audience = AudienceType.PRIVATE;
  let members: IMember[] = [];
  let org: CF_ThreadMembershipContext_Discussion['org'] = null;

  switch (thread.__typename) {
    case 'Discussion':
    case 'Meeting':
    case 'Team':
      audience = thread.audience || AudienceType.PRIVATE;
      members = thread.membership?.filterNullish().map(({ account }) => account) || [];
      org = thread.org;
      break;
    case 'PrivateChat':
      // Create a mutable copy to keep TS happy.
      members = [...thread.participants];
      org = thread.org;
      break;
    case 'Replies':
      // Recurse up to the root thread
      if (
        thread.parentThreadEvent.thread.__typename === 'Discussion' ||
        thread.parentThreadEvent.thread.__typename === 'PrivateChat' ||
        thread.parentThreadEvent.thread.__typename === 'Team'
      ) {
        return _getThreadContext(thread.parentThreadEvent.thread);
      }
      Logger.error(`Unexpected parentThreadEvent type: ${thread.parentThreadEvent.thread.__typename}`);
      break;
    default:
      exhaustive(thread, `Unexpected thread type: ${(thread as any).__typename}`);
  }

  return { audience, members, org };
}

export interface IPaneHeaderThreadContextProps {
  showAudienceBadge?: boolean;
  thread: CF_ThreadMembershipContext;
  viewer: CF_PaneHeaderThreadContextViewer | null;
}

function PaneHeaderThreadContext({ showAudienceBadge, thread, viewer }: IPaneHeaderThreadContextProps) {
  const account = useLoggedInAccount();
  const { audience, members, org } = _getThreadContext(thread);

  // Identify members not included in thread org scope
  const extendedMembers = members.filter((member) => member?.org?.id !== org?.id);
  const orgName = org?.name ?? 'Your Organization';

  // Note: we don't show external badge for orgless viewers since *everything* is external to them.
  const externalMembers = members.filter(
    (member) =>
      viewer?.org?.id &&
      viewer.org.id !== member?.org?.id &&
      (audience !== AudienceType.ORG || org?.id !== member?.org?.id) // Filter out members redundant with thread org
  );
  const isExternalOrg = viewer?.org?.id && org?.id && viewer.org.id !== org.id;

  const options = getAudienceOptions({ orgName });
  const { Icon } = options[audience];

  let audienceTooltip;
  let externalTooltip;
  switch (audience) {
    case AudienceType.PRIVATE:
      audienceTooltip = members.map((m) => <_Member {...m} account={account} key={m.id} />);
      externalTooltip = externalMembers.map((m) => <_Member {...m} account={account} key={m.id} />);
      break;
    case AudienceType.ORG:
      audienceTooltip = (
        <>
          Visible to {orgName}
          {extendedMembers.filterNullish().map((m) => (
            <_Member {...m} account={account} key={m.id} />
          ))}
        </>
      );
      externalTooltip = (
        <>
          {isExternalOrg && <>Visible to {orgName}</>}
          {externalMembers.filterNullish().map((m) => (
            <_Member {...m} account={account} key={m.id} />
          ))}
        </>
      );
      break;
    case AudienceType.PUBLIC:
      audienceTooltip = 'Visible to anyone with a link.';
      externalTooltip = 'Visible to anyone with a link.';
      break;
  }

  return (
    <_ContextList>
      {showAudienceBadge && (
        <_ContextItem>
          <Tooltip placement="bottom" title={<_Tooltip>{audienceTooltip}</_Tooltip>}>
            <_Audience>
              <Icon size={12} />
            </_Audience>
          </Tooltip>
        </_ContextItem>
      )}
      {(isExternalOrg || !!externalMembers.length) && (
        <_ContextItem>
          <Tooltip placement="bottom" title={<_Tooltip>{externalTooltip}</_Tooltip>}>
            <ExternalBadge />
          </Tooltip>
        </_ContextItem>
      )}
    </_ContextList>
  );
}

PaneHeaderThreadContext.fragment = gql`
  fragment CF_ThreadMembershipContextAccount on Account {
    id
    name
    org {
      id
    }
  }

  fragment CF_ThreadMembershipContextInner on IThread {
    org {
      id
      name
    }

    ... on PrivateChat {
      participants {
        id
        ...CF_ThreadMembershipContextAccount
      }
    }

    ... on Discussion {
      audience
      membership {
        account {
          id
          ...CF_ThreadMembershipContextAccount
        }
      }
    }

    ... on Meeting {
      audience
      membership {
        account {
          id
          ...CF_ThreadMembershipContextAccount
        }
      }
    }

    ... on Team {
      audience
      membership {
        account {
          id
          ...CF_ThreadMembershipContextAccount
        }
      }
    }
  }

  fragment CF_ThreadMembershipContext on IThread {
    id
    ...CF_ThreadMembershipContextInner

    # For replies & meetings, recurse up to the root thread
    ... on Replies {
      id
      parentThreadEvent {
        id
        thread {
          id
          name
          permalink
          ...CF_ThreadMembershipContextInner
        }
      }
    }
    ... on Meeting {
      id
      meetingParticipants {
        id
        account {
          ...CF_ThreadMembershipContextAccount
        }
      }
    }
  }

  fragment CF_PaneHeaderThreadContext on IThread {
    ...CF_ThreadMembershipContext
    ...CF_ThreadOriginBreadcrumbs
  }
  ${ThreadOriginBreadcrumbs.fragment}
`;

PaneHeaderThreadContext.viewerFragment = gql`
  fragment CF_PaneHeaderThreadContextViewer on Account {
    id
    org {
      id
      name
    }
  }
`;

export default PaneHeaderThreadContext;

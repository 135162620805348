import { ApolloClient } from '@apollo/client';
import {
  RTDescendantNodes,
  RTElementNode,
  RTElementProp,
  RTElementType,
  RTEmojiElementNode,
  RTFileElementNode,
  RTImageElementNode,
  RTMediaElementNode,
  RTMessageRefElementNode,
  RTSerializer,
  RTTextNode,
  RTTextNodeMark,
  SUPPORTED_IMAGE_TYPES,
  fromSlateNodes,
  getSlugFromUnifiedId,
  toSlateNodes,
} from '@rmvw/x-common';
import { YjsEditor } from '@slate-yjs/core';
import { BaseEditor, Editor, Node, Point, Range, Element as SlateElement, Text, Transforms } from 'slate';
import { HistoryEditor } from 'slate-history';
import { ReactEditor } from 'slate-react';

import Logger from '../observability/Logger';

import {
  AT_MENTION_PREFIXES,
  getEmailMatches,
  getEmojiMatches,
  getLinkMatches,
  getMentionMatches,
} from './EditorUtils';
import RTNodeResolver, { IRTUploadElementNodeResolverData } from './RTNodeResolver';

//
// Editor helpers
//

export interface IRTEditorExtensions {
  getResolverStatus: (resolverId: string) => IResolverStatus | undefined;
  insertFragmentAndResolveNodes: (nodes: RTDescendantNodes) => void;
  isResolvingNodes: () => boolean;
}

export type RTEditor = IRTEditorExtensions & BaseEditor & ReactEditor & HistoryEditor & YjsEditor;

const BLOCKQUOTE_START_REGEXP = /^(>|\|)\s$/;
const CODE_BLOCK_END_REGEXP = /```$/;
const CODE_BLOCK_START_REGEXP = /^```$/;
const HEADING_START_REGEXP = /^(#{1,6})\s$/;

const ORDERED_LIST_START_REGEXP = /^(1\.)\s$/;
const UNORDERED_LIST_START_REGEXP = /^(\*|-)\s$/;

const _styleShortcutTokens: Array<{ token: string; mark: RTTextNodeMark }> = [
  { token: '***', mark: RTTextNodeMark.BOLDITALIC },
  { token: '___', mark: RTTextNodeMark.BOLDITALIC },
  { token: '__*', mark: RTTextNodeMark.BOLDITALIC },
  { token: '**_', mark: RTTextNodeMark.BOLDITALIC },
  { token: '**', mark: RTTextNodeMark.BOLD },
  { token: '__', mark: RTTextNodeMark.BOLD },
  { token: '*', mark: RTTextNodeMark.BOLD }, // Prioritizing Slack compat over markdown compat
  { token: '~~', mark: RTTextNodeMark.STRIKETHROUGH },
  { token: '~', mark: RTTextNodeMark.STRIKETHROUGH },
  { token: '_', mark: RTTextNodeMark.ITALIC },
  { token: '`', mark: RTTextNodeMark.CODE },
];

// Compile RegExps for each of the shortcuts
const _escapeToken = (token: string) => token.replace(/([*])/g, '\\$1');
const styleShortcuts: Array<{ start: RegExp; end: RegExp; mark: RTTextNodeMark }> = _styleShortcutTokens.map((s) => ({
  ...s,
  start: new RegExp(`^(${_escapeToken(s.token)})[^\\s\`*~_]`),
  end: new RegExp(`[^\\s\`*~_](${_escapeToken(s.token.split('').reverse().join(''))})$`),
}));

export interface IResolverStatus {
  data?: { file?: File };
  error?: Error;
  getProgress: () => number;
}

export const withRTExtensions = (editor: RTEditor, args: { apolloClient: ApolloClient<object> }) => {
  const {
    deleteBackward: _deleteBackward,
    insertBreak: _insertBreak,
    insertData: _insertData,
    insertFragmentData: _insertFragmentData,
    insertText: _insertText,
    normalizeNode: _normalizeNode,
  } = editor;

  editor.insertBreak = () => {
    //
    // Always use soft-breaks inside of CODE blocks
    //
    if (isElementActive(editor, RTElementType.CODE)) {
      editor.insertText('\n');
      return;
    }

    // If we are not already inside of a LINK element, see if there is a trailing URL
    // we need to linkify before inserting break
    if (!isElementActive(editor, RTElementType.LINK)) {
      findAndLinkifyTrailingTextUrl(editor);
      // vv continue vv
    }

    // Handle special behavior for block elements
    const blockAbove = Editor.above(editor, { match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n) });
    if (blockAbove) {
      const [ancestorBlock] = blockAbove;
      if (!Editor.isEditor(ancestorBlock) && SlateElement.isElement(ancestorBlock)) {
        const isEmptyBlock = !Node.string(ancestorBlock);
        if (ancestorBlock.type === RTElementType.HEADING) {
          // Always exit heading elements on enter
          Transforms.insertNodes(editor, { type: RTElementType.DIV, children: [{ text: '' }] });
          return;
        } else if (isEmptyBlock) {
          // If we are within a LIST_ITEM element, unwind the parent list elements
          if (isElementActive(editor, RTElementType.LIST_ITEM)) {
            Editor.withoutNormalizing(editor, () => {
              Transforms.unwrapNodes(editor, {
                match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === RTElementType.LIST_ITEM,
                split: true,
              });
              Transforms.unwrapNodes(editor, {
                match: (n) =>
                  !Editor.isEditor(n) &&
                  SlateElement.isElement(n) &&
                  [RTElementType.BULLETED_LIST, RTElementType.ORDERED_LIST].includes(n.type),
                split: true,
              });
            });
            return;
          }

          if (ancestorBlock.type !== RTElementType.DIV) {
            // Downgrade the current block back to DIV. This allows the user to "double enter"
            // to exit the current block type and reset to a DIV
            Transforms.setNodes(editor, { type: RTElementType.DIV });
            return;
          }
        }
      }
    }

    // Default behavior
    _insertBreak();
  };

  editor.insertSoftBreak = () => {
    editor.insertText('\n');
  };

  editor.insertText = (text) => {
    _insertText(text);

    //
    // Intercept shortcut character sequences
    //

    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      // Get the current block and the text from the start of the block to the cursor
      const currentBlock = Editor.above(editor, {
        match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n),
      });
      const currentBlockPath = currentBlock?.[1] ?? [];
      const currentBlockAnchor = Editor.start(editor, currentBlockPath);
      const currentBlockTextToCursor = Editor.string(editor, { anchor: currentBlockAnchor, focus: selection.focus });

      ////////////////////
      //
      // Style shortcuts
      //
      ////////////////////

      if (!isCodeActive(editor)) {
        // Make sure we're at the last character of the current word
        const after = Editor.after(editor, selection);
        if (!after || Editor.string(editor, { anchor: selection.anchor, focus: after }).match(/^(\s|$)/)) {
          for (const shortcut of styleShortcuts) {
            // Do we see a matching end token?
            const endMatch = currentBlockTextToCursor.match(shortcut.end);
            if (!endMatch) {
              continue;
            }

            const end = Editor.before(editor, selection, { distance: endMatch[1].length });
            if (!end) {
              continue;
            }

            // Advance start cursor to the beginning of the next word to look for qualifying start token
            let start = end;
            while (Point.isBefore(currentBlockAnchor, start)) {
              start = Editor.before(editor, start, { unit: 'word' }) ?? currentBlockAnchor;

              // Note that the `Editor.before` API excludes certain special characters when advancing by `words`,
              // so we must manually advance cursor until we spot our first whitespace
              while (Point.isBefore(currentBlockAnchor, start)) {
                const next = Editor.before(editor, start);
                if (next && Editor.string(editor, { anchor: next, focus: start }).match(/^(\s|$)/)) {
                  break;
                }
                start = next ?? currentBlockAnchor;
              }

              // If current leaf is an inline CODE block, keep looking
              const [leaf] = Editor.leaf(editor, start);
              if (leaf[RTTextNodeMark.CODE]) {
                continue;
              }

              const startMatch = Editor.string(editor, Editor.range(editor, start, end), { voids: true }).match(
                shortcut.start
              );
              if (startMatch) {
                const currentMarks = Editor.marks(editor);

                // The following operations should not be saved to the undo history
                HistoryEditor.withoutSaving(editor, () => start && Transforms.setSelection(editor, { anchor: start }));

                // Delete start token, without merging history to allow for easy undo
                HistoryEditor.withoutMerging(editor, () => {
                  Transforms.delete(editor, {
                    at: editor.selection?.anchor,
                    distance: startMatch[1].length,
                  });
                });

                // Delete the end token
                Transforms.delete(editor, {
                  at: editor.selection?.focus,
                  distance: endMatch[1].length,
                  reverse: true,
                });

                // Apply appropriate style to the selected Nodes
                Transforms.setNodes<Text>(
                  editor,
                  { [shortcut.mark]: true },
                  { match: (n) => Text.isText(n) && !n[shortcut.mark], split: true }
                );

                // Reset selection (without saving the collapse option to undo history)
                HistoryEditor.withoutSaving(editor, () => Transforms.collapse(editor, { edge: 'focus' }));

                // Restore mark at cursor so user can keep typing without interruption
                if (!currentMarks?.[shortcut.mark]) {
                  Editor.removeMark(editor, shortcut.mark);
                }

                return;
              }
            }
          }
        }
      }

      //////////////////////
      //
      // Element shortcuts
      //
      //////////////////////

      //
      // Blockquote element
      //

      if (isElementActive(editor, RTElementType.DIV)) {
        const blockquoteStartMatch = currentBlockTextToCursor.match(BLOCKQUOTE_START_REGEXP);
        if (blockquoteStartMatch) {
          // Delete the blockquote start marker, manipulating history such that the replacement can be easily undone
          HistoryEditor.withoutMerging(editor, () =>
            Transforms.delete(editor, { distance: blockquoteStartMatch[0].length, unit: 'character', reverse: true })
          );

          // Change current node to blockquote
          Transforms.setNodes(
            editor,
            { type: RTElementType.BLOCKQUOTE },
            { match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n) }
          );
          return;
        }
      }

      //
      // Bulleted list element
      //

      if (isElementActive(editor, RTElementType.DIV) && !isElementActive(editor, RTElementType.LIST_ITEM)) {
        const bulletedListStartMatch = currentBlockTextToCursor.match(UNORDERED_LIST_START_REGEXP);
        if (bulletedListStartMatch) {
          // Delete the bulleted list start marker, manipulating history such that the replacement can be easily undone
          HistoryEditor.withoutMerging(editor, () =>
            Transforms.delete(editor, { distance: bulletedListStartMatch[0].length, unit: 'character', reverse: true })
          );

          // Wrap current block node in LIST_ITEM and BULLETED_LIST
          Editor.withoutNormalizing(editor, () => {
            Transforms.wrapNodes(
              editor,
              { type: RTElementType.LIST_ITEM, children: [] },
              { match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n) }
            );
            Transforms.wrapNodes(
              editor,
              { type: RTElementType.BULLETED_LIST, children: [] },
              { match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === RTElementType.LIST_ITEM }
            );
          });
          return;
        }
      }

      //
      // Ordered list element
      //

      if (isElementActive(editor, RTElementType.DIV) && !isElementActive(editor, RTElementType.LIST_ITEM)) {
        const orderedListStartMatch = currentBlockTextToCursor.match(ORDERED_LIST_START_REGEXP);
        if (orderedListStartMatch) {
          // Delete the ordered list start marker, manipulating history such that the replacement text can be easily undone with Ctrl-Z
          HistoryEditor.withoutMerging(editor, () =>
            Transforms.delete(editor, { distance: orderedListStartMatch[0].length, unit: 'character', reverse: true })
          );

          // Wrap current block node in LIST_ITEM and ORDERED_LIST
          Editor.withoutNormalizing(editor, () => {
            Transforms.wrapNodes(
              editor,
              { type: RTElementType.LIST_ITEM, children: [] },
              { match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n) }
            );
            Transforms.wrapNodes(
              editor,
              { type: RTElementType.ORDERED_LIST, children: [] },
              { match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === RTElementType.LIST_ITEM }
            );
          });

          // Skip further processing
          return;
        }
      }

      //
      // Code block start
      //

      if (isElementActive(editor, RTElementType.DIV)) {
        const codeStartMatch = currentBlockTextToCursor.match(CODE_BLOCK_START_REGEXP);
        if (codeStartMatch) {
          // Delete the start marker, manipulating history such that the replacement can be easily undone
          HistoryEditor.withoutMerging(editor, () =>
            Transforms.delete(editor, { distance: codeStartMatch[0].length, unit: 'character', reverse: true })
          );

          // Change current node to code block
          Transforms.setNodes(
            editor,
            { type: RTElementType.CODE },
            { match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n) }
          );
          return;
        }
      }

      //
      // Code block end
      //

      if (isElementActive(editor, RTElementType.CODE)) {
        const codeEndMatch = currentBlockTextToCursor.match(CODE_BLOCK_END_REGEXP);
        if (codeEndMatch) {
          // Delete the end marker, manipulating history such that the replacement can be easily undone
          HistoryEditor.withoutMerging(editor, () =>
            Transforms.delete(editor, { distance: 3, unit: 'character', reverse: true })
          );

          // Insert new div beneath code block
          Transforms.insertNodes(editor, { type: RTElementType.DIV, children: [{ text: '' }] });
          return;
        }
      }

      //
      // Replace text that looks like a URL with a link element if it's one character before the cursor
      //

      if (!isCodeActive(editor) && !isElementActive(editor, RTElementType.LINK)) {
        if (findAndLinkifyTrailingTextUrl(editor, { cursorOffset: 1 })) {
          // We found a link -- stop further processing
          return;
        }
      }

      //
      // Heading Element
      //

      if (isElementActive(editor, RTElementType.DIV)) {
        const headingMatch = currentBlockTextToCursor.match(HEADING_START_REGEXP);
        if (headingMatch) {
          // Delete the heading start marker, manipulating history such that the replacement can be easily undone
          HistoryEditor.withoutMerging(editor, () =>
            Transforms.delete(editor, { distance: headingMatch[0].length, unit: 'character', reverse: true })
          );

          // Change current node to heading
          Transforms.setNodes(
            editor,
            { type: RTElementType.HEADING, [RTElementProp.HEADING__LEVEL]: headingMatch[1].length.toString() },
            { match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n) }
          );
          return;
        }
      }
    }
  };

  editor.deleteBackward = (args) => {
    const { selection } = editor;
    if (selection && Range.isCollapsed(selection)) {
      const above = Editor.above(editor, { match: (n) => SlateElement.isElement(n) && Editor.isBlock(editor, n) });
      if (above) {
        const [ancestor, path] = above;
        const start = Editor.start(editor, path);
        if (!Editor.isEditor(ancestor) && SlateElement.isElement(ancestor) && Point.equals(selection.anchor, start)) {
          if (isElementActive(editor, RTElementType.LIST_ITEM)) {
            // If we are within a LIST_ITEM element...
            Editor.withoutNormalizing(editor, () => {
              // Remove the LIST_ITEM element
              Transforms.unwrapNodes(editor, {
                match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === RTElementType.LIST_ITEM,
              });
              // Split out the parent BULLETED_LIST or ORDERED_LIST element
              Transforms.unwrapNodes(editor, {
                match: (n) =>
                  !Editor.isEditor(n) &&
                  SlateElement.isElement(n) &&
                  [RTElementType.BULLETED_LIST, RTElementType.ORDERED_LIST].includes(n.type),
                split: true,
              });
            });
            return;
          }

          if (ancestor.type !== RTElementType.DIV) {
            // Downgrade the current block back to DIV. This allows the user to "delete"
            // to exit the current block type and reset to a DIV
            Transforms.setNodes(editor, { type: RTElementType.DIV });
            return;
          }
        }
      }
    }

    _deleteBackward(args);
  };

  editor.insertData = (data) => {
    //
    // Inside of code sections, always paste plaintext
    //

    if (isCodeActive(editor)) {
      Transforms.insertNodes(editor, { text: data.getData('text') });
      return;
    }

    //
    // Handle pasted links on selected text in non-code sections
    //

    const text = data.getData('text').trim();
    const linkMatch = getLinkMatches(text).at(-1);
    if (linkMatch?.indexStart === 0 && linkMatch.indexEnd === text.length && !isCodeActive(editor)) {
      const parsedUrl = new URL(text.startsWith('http') ? text : `https://${text}`);
      if (editor.selection && Range.isExpanded(editor.selection)) {
        // If we have an active selection, use the selected elements as the inner content for the link
        // Remove any links that may already exist on selected elements
        Transforms.unwrapNodes(editor, { match: (n) => SlateElement.isElement(n) && n.type === RTElementType.LINK });

        // Apply the new link
        Transforms.wrapNodes(
          editor,
          { type: RTElementType.LINK, [RTElementProp.LINK__HREF]: parsedUrl.toString(), children: [] },
          { split: true }
        );

        // Stop further processing
        return;
      }
    }

    //
    // Handle Slate fragment (e.g., copy-paste from within Slate context)
    //

    if (_insertFragmentData?.(data)) {
      return;
    }

    //
    // Handle HTML data
    //

    const htmlData = data.getData('text/html');
    if (htmlData) {
      const nodes = RTSerializer.fromHtml(htmlData);
      if (nodes) {
        editor.insertFragmentAndResolveNodes(nodes);
        return;
      }
    }

    //
    // Handle plaintext data
    //

    const textData = data.getData('text');
    if (textData) {
      // Run plaintext thru Markdown parser by default (to automatically linkify URLs, etc.)
      // We append a space to the text to ensure that formatting is cleared after the text
      const nodes = RTSerializer.fromMarkdown(textData) ?? RTSerializer.fromPlaintext(textData);
      editor.insertFragmentAndResolveNodes(nodes);

      return;
    }

    //
    // Handle file data
    //

    const files = data.files;
    if (files?.length) {
      [...files].map((file) => insertFile(editor, file));
      return;
    }

    //
    // Default case
    //

    _insertData(data);
  };

  /**
   * Override normalizeNode
   */
  editor.normalizeNode = (entry) => {
    const [node, path] = entry;

    // All children of CODE elements should be text
    if (SlateElement.isElement(node) && node.type === RTElementType.CODE) {
      for (const [child, childPath] of Node.children(editor, path)) {
        // There should no child elements of CODE element (only TEXT)
        if (SlateElement.isElement(child)) {
          Transforms.unwrapNodes(editor, { at: childPath });
          return;
        }
      }
    }

    // All children of BLOCKQUOTE elements should be text or inline elements
    if (SlateElement.isElement(node) && node.type === RTElementType.BLOCKQUOTE) {
      for (const [child, childPath] of Node.children(editor, path)) {
        // There should no non-inline child elements of BLOCKQUOTE
        if (SlateElement.isElement(child) && !editor.isInline(child)) {
          Transforms.unwrapNodes(editor, { at: childPath });
          return;
        }
      }
    }

    // LIST_ITEM elements must always be children of BULLETED_LIST or ORDERED_LIST
    if (SlateElement.isElement(node) && node.type === RTElementType.LIST_ITEM) {
      const parent = Node.parent(editor, path);
      if (
        !parent ||
        !SlateElement.isElement(parent) ||
        ![RTElementType.BULLETED_LIST, RTElementType.ORDERED_LIST].includes(parent.type)
      ) {
        Transforms.wrapNodes(editor, { type: RTElementType.BULLETED_LIST, children: [] }, { at: path });
        return;
      }
    }

    // Only LIST_ITEM elements can be children of BULLETED_LIST or ORDERED_LIST
    if (SlateElement.isElement(node) && [RTElementType.BULLETED_LIST, RTElementType.ORDERED_LIST].includes(node.type)) {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (SlateElement.isElement(child) && !editor.isInline(child) && child.type !== RTElementType.LIST_ITEM) {
          const prev = Editor.previous(editor, { at: childPath });
          if (prev && [RTElementType.BULLETED_LIST, RTElementType.ORDERED_LIST].includes(child.type)) {
            // Invalid nested list element...move it to be a child of preceding LIST_ITEM
            const [prevNode, prevPath] = prev;
            if (!SlateElement.isElement(prevNode) || prevNode.type !== RTElementType.LIST_ITEM) {
              Logger.error(`Unexpected child type ${(prevNode as any).type} within ${node.type} element!`);
            } else {
              Transforms.moveNodes(editor, { at: childPath, to: prevPath.concat(prevNode.children.length) });
            }
          } else {
            // Wrap the offending node inside of a LIST_ITEM
            Transforms.wrapNodes(editor, { type: RTElementType.LIST_ITEM, children: [] }, { at: childPath });
          }
          return;
        }
      }
    }

    // Merge adjacent BULLETED_LIST and ORDERED_LIST elements together
    if (SlateElement.isElement(node) && [RTElementType.BULLETED_LIST, RTElementType.ORDERED_LIST].includes(node.type)) {
      const next = Editor.next(editor, { at: path });
      const prev = Editor.previous(editor, { at: path });

      if (next && SlateElement.isElement(next[0]) && next[0].type === node.type) {
        Transforms.mergeNodes(editor, { at: next[1] });
        return;
      }

      if (prev && SlateElement.isElement(prev[0]) && prev[0].type === node.type) {
        Transforms.mergeNodes(editor, { at: path });
        return;
      }
    }

    // To keep nested lists manageable, we:
    //   a/ Restrict the first child of LIST_ITEM element to be a block element.
    //   b/ Allow for an optional second child that is either a BULLET_LIST or ORDERED_LIST. This is to support nested lists.
    //   c/ All other children are moved into their own LIST_ITEM elements, subject to (a) and (b) recursively.
    if (SlateElement.isElement(node) && node.type === RTElementType.LIST_ITEM) {
      if (node.children.length > 0) {
        // Normalize first child to always be a DIV
        const firstChild = node.children[0];

        if (Text.isText(firstChild) || (SlateElement.isElement(firstChild) && editor.isInline(firstChild))) {
          // If first child is an inline/text element, wrap all children in a DIV block
          Editor.withoutNormalizing(editor, () => {
            // Set parent to DIV and then wrap parent in new LIST_ITEM
            Transforms.setNodes(editor, { type: RTElementType.DIV }, { at: path });
            Transforms.wrapNodes(editor, { type: RTElementType.LIST_ITEM, children: [] }, { at: path });
          });
          return;
        }

        if (
          SlateElement.isElement(firstChild) &&
          [RTElementType.BULLETED_LIST, RTElementType.ORDERED_LIST].includes(firstChild.type)
        ) {
          // If first child is a BULLETED_LIST or ORDERED_LIST, insert a blank DIV before it as the new first child
          Transforms.insertNodes(editor, { type: RTElementType.DIV, children: [{ text: '' }] }, { at: path.concat(0) });
          return;
        }

        // If second (block) child is NOT a BULLETED_LIST or ORDERED_LIST, split it out into a new LIST_ITEM
        const secondChild = node.children[1];
        if (
          secondChild &&
          (!SlateElement.isElement(secondChild) ||
            ![RTElementType.BULLETED_LIST, RTElementType.ORDERED_LIST].includes(secondChild.type))
        ) {
          Transforms.splitNodes(editor, { at: path.concat(1) });
          return;
        }

        // Split any additional (block) children out into a their own LIST_ITEMs
        if (node.children.length > 2) {
          Transforms.splitNodes(editor, { at: path.concat(2) });
          return;
        }
      }
    }

    // Prune empty LINK elements
    if (SlateElement.isElement(node) && node.type === RTElementType.LINK) {
      const isEmpty =
        !node[RTElementProp.LINK__HREF] || // no href prop
        [...Node.children(editor, path)].every(([child]) => Text.isText(child) && !Node.string(child)); // no children
      if (isEmpty) {
        Transforms.unwrapNodes(editor, { at: path });
        return;
      }
    }

    // Prune nested DIV elements: <div><div>...</div></div> --> <div>...</div>
    if (SlateElement.isElement(node) && node.type === RTElementType.DIV) {
      for (const [child, childPath] of Node.children(editor, path)) {
        if (SlateElement.isElement(child) && child.type === RTElementType.DIV) {
          Transforms.unwrapNodes(editor, { at: childPath });
          return;
        }
      }
    }

    // Make sure block elements are not nested inside of inline elements
    if (SlateElement.isElement(node) && !editor.isInline(node)) {
      const parent = Editor.parent(editor, path);
      if (parent && SlateElement.isElement(parent[0]) && editor.isInline(parent[0])) {
        Transforms.unwrapNodes(editor, { at: path });
        return;
      }
    }

    // Default
    return _normalizeNode(entry);
  };

  /**
   * Override isInline to indicate elements which are NOT block elements
   */
  editor.isInline = (element): boolean => {
    if (Editor.isEditor(element)) {
      return false;
    }

    const node = fromSlateNodes([element])[0];
    return node instanceof RTElementNode && node.isInline();
  };

  /**
   * Override isVoid to indicate elements which are void elements (e.g., children are not text editable)
   */
  editor.isVoid = (element): boolean => {
    if (Editor.isEditor(element)) {
      return false;
    }

    const node = fromSlateNodes([element])[0];
    return node instanceof RTElementNode && node.isVoid();
  };

  const _resolverStatus = new Map<string, IResolverStatus>(); // <resolverId, IUploadStatus>

  /**
   * Insert fragment and resolve any outstanding nodes
   */
  editor.insertFragmentAndResolveNodes = (nodes: RTDescendantNodes) => {
    Transforms.insertFragment(editor, toSlateNodes(nodes));
    RTNodeResolver.resolveNodes(editor, args.apolloClient, nodes, {
      onResolverCompleted: (resolverId: string) => {
        const status = _resolverStatus.get(resolverId);
        if (status) {
          status.getProgress = () => 1;
          setTimeout(() => _resolverStatus.delete(resolverId), 500); // Clean up status after 500ms
        }
      },
      onResolverError: (resolverId: string, error: Error) => {
        const status = _resolverStatus.get(resolverId);
        if (status) {
          status.error = error;
        }
      },
      onResolverStarted: (resolverId: string, data?: { file?: File }) => {
        const _t0 = Date.now();
        _resolverStatus.set(resolverId, {
          data,
          getProgress: () => 1 - 1 / (1 + (Date.now() - _t0) / 2500), // fictitious progress function
        });
      },
    });
  };

  /**
   *
   */
  editor.isResolvingNodes = () => {
    const result = Editor.nodes(editor, {
      at: { anchor: Editor.start(editor, []), focus: Editor.end(editor, []) },
      match: (n) => SlateElement.isElement(n) && !!n[RTElementProp.__RESOLVER_ID],
    });

    return !result.next().done;
  };

  /**
   * Returns upload status for the given resolverId
   */
  editor.getResolverStatus = (resolverId: string) => {
    return _resolverStatus.get(resolverId);
  };

  return editor;
};

export const isMarkActive = (editor: Editor, mark: RTTextNodeMark): boolean => {
  return Editor.marks(editor)?.[mark] === true || false;
};

export const toggleMark = (editor: Editor, mark: RTTextNodeMark): void => {
  if (isMarkActive(editor, mark)) {
    Editor.removeMark(editor, mark);
  } else {
    Editor.addMark(editor, mark, true);
  }
};

export const isElementActive = (editor: Editor, type: RTElementType): boolean => {
  const { selection } = editor;
  if (!selection) {
    return false;
  }

  const [match] = [
    ...Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === type,
    }),
  ];

  return !!match;
};

export const isCodeActive = (editor: Editor): boolean => {
  return isElementActive(editor, RTElementType.CODE) || isMarkActive(editor, RTTextNodeMark.CODE);
};

export const insertMediaObject = (editor: Editor, mediaId: string): void => {
  Transforms.insertFragment(
    editor,
    toSlateNodes([
      new RTTextNode(''), // buffer with empty text element
      new RTMediaElementNode([new RTTextNode('')], { [RTElementProp.MEDIA__MEDIA_ID]: mediaId }),
      new RTTextNode(''), // buffer with empty text element
    ])
  );
};

export const insertFile = (editor: RTEditor, file: File): void => {
  const fileId = `${file.name.replace(/\s/g, '.')}-${file.lastModified}-${file.size}`;
  let nodes: RTDescendantNodes;

  if (SUPPORTED_IMAGE_TYPES.includes(file.type)) {
    nodes = [
      new RTTextNode(''), // pad with empty text elements
      new RTImageElementNode<IRTUploadElementNodeResolverData>(
        [new RTTextNode(file.name)],
        {},
        { id: fileId, data: { file } }
      ),
      new RTTextNode(''),
    ];
  } else if (file.type.startsWith('video/') || file.type.startsWith('audio/')) {
    nodes = [
      new RTTextNode(''), // pad with empty text elements
      new RTMediaElementNode<IRTUploadElementNodeResolverData>(
        [new RTTextNode(file.name)],
        {},
        { id: fileId, data: { file } }
      ),
      new RTTextNode(''),
    ];
  } else {
    nodes = [
      new RTTextNode(''), // pad with empty text elements
      new RTFileElementNode<IRTUploadElementNodeResolverData>(
        [new RTTextNode(file.name)],
        {},
        { id: fileId, data: { file } }
      ),
      new RTTextNode(''),
    ];
  }

  editor.insertFragmentAndResolveNodes(nodes);
};

export const insertMessageRef = (editor: RTEditor, messageId: string): void => {
  const nodes = [new RTMessageRefElementNode(undefined, { i: messageId }), new RTTextNode('')];

  editor.insertFragmentAndResolveNodes(nodes);
};

/**
 * Looks for a URL ending at the current cursor position - `cursorOffset` and linkifies it
 * @param editor
 * @param cursorOffset Optional offset to left of cursor from which to start searching for a link
 *
 * @returns true if a link was found and linkified, false otherwise
 */
export const findAndLinkifyTrailingTextUrl = (editor: Editor, args?: { cursorOffset?: number }): boolean => {
  const { selection } = editor;
  if (!selection || !Range.isCollapsed(selection)) {
    return false;
  }

  const currentPath = selection.anchor.path;
  const currentNode = Node.get(editor, currentPath);
  if (!Text.isText(currentNode)) {
    return false;
  }
  const { text } = currentNode;

  const linkMatch = getLinkMatches(text)?.at(-1); // last match
  if (linkMatch?.indexEnd !== text.length - (args?.cursorOffset ?? 0)) {
    return false;
  }

  const linkRange = {
    anchor: { path: currentPath, offset: linkMatch.indexStart },
    focus: { path: currentPath, offset: linkMatch.indexEnd },
  };

  wrapLink(editor, linkRange, linkMatch.match);

  return true;
};

/**
 * Wrap the current selection in a link element
 * @param editor Slate editor
 * @param linkRange Range of the text to be linkified
 * @param href URL to link to
 */
export const wrapLink = (editor: Editor, linkRange: Range, href: string): void => {
  // If this text is already (partially) linkified, unwrap first
  Transforms.unwrapNodes(editor, {
    at: linkRange,
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === RTElementType.LINK,
    split: true,
  });

  const parsedUrl = new URL(href.startsWith('http') ? href : `https://${href}`);

  Transforms.wrapNodes(
    editor,
    { type: RTElementType.LINK, [RTElementProp.LINK__HREF]: parsedUrl.toString(), children: [] },
    { at: linkRange, split: true }
  );
};

/**
 * Looks for a candidate @ mention from the current cursor position
 *
 * @param editor
 * @param args  startMark: string to match the start of a mention, maxWords: maximum number of words to scan for mention
 * @returns range if found, null otherwise
 */
export const findMentionAtCursor = (editor: Editor, args: { maxMentionWordCount: number }): Range | null => {
  const { selection } = editor;
  if (!selection || Range.isExpanded(selection)) {
    return null;
  }

  const currentPath = selection.anchor.path;
  const currentNode = Node.get(editor, currentPath);
  if (!Text.isText(currentNode)) {
    return null;
  }
  const { text } = currentNode;

  // If the cursor is immediately *after* a mention symbol that doesn't have an alphanumeric prefix, return the mention
  if (
    selection.anchor.offset > 0 &&
    AT_MENTION_PREFIXES.includes(text[selection.anchor.offset - 1]) &&
    (selection.anchor.offset === 1 || text[selection.anchor.offset - 2].match(/\p{P}|\s/u))
  ) {
    return {
      anchor: { path: currentPath, offset: selection.anchor.offset - 1 },
      focus: { path: currentPath, offset: selection.anchor.offset },
    };
  }

  // Find all mentions in the current text
  const matches = getMentionMatches(text, { maxWords: args.maxMentionWordCount });

  // Find the mention that intersects with the current cursor position
  for (const match of matches) {
    if (selection.anchor.offset >= match.indexStart && selection.anchor.offset <= match.indexEnd) {
      // Find next word boundary in `text` after the current cursor position
      const indexNextWord = text.indexOf(' ', selection.anchor.offset);
      return {
        anchor: { path: currentPath, offset: match.indexStart },
        focus: { path: currentPath, offset: indexNextWord >= 0 ? indexNextWord : match.indexEnd },
      };
    }
  }

  return null;
};

export const findEmailAtCursor = (editor: Editor): Range | null => {
  const { selection } = editor;
  if (!selection || Range.isExpanded(selection)) {
    return null;
  }

  const currentPath = selection.anchor.path;
  const currentNode = Node.get(editor, currentPath);
  if (!Text.isText(currentNode)) {
    return null;
  }
  const { text } = currentNode;

  // Find all emails in the current text
  const matches = getEmailMatches(text);

  // Find the email that intersects with the current cursor position
  for (const match of matches) {
    if (selection.anchor.offset >= match.indexStart && selection.anchor.offset <= match.indexEnd) {
      return {
        anchor: { path: currentPath, offset: match.indexStart },
        focus: { path: currentPath, offset: match.indexEnd },
      };
    }
  }
  return null;
};

export const findEmojiAtCursor = (editor: Editor): Range | null => {
  const { selection } = editor;
  if (!selection || Range.isExpanded(selection)) {
    return null;
  }

  const currentPath = selection.anchor.path;
  const currentNode = Node.get(editor, currentPath);
  if (!Text.isText(currentNode)) {
    return null;
  }
  const { text } = currentNode;

  // Find all emojis in the current text
  const matches = getEmojiMatches(text);

  // Find the emoji that intersects with the current cursor position
  for (const match of matches) {
    if (selection.anchor.offset >= match.indexStart && selection.anchor.offset <= match.indexEnd) {
      return {
        anchor: { path: currentPath, offset: match.indexStart },
        focus: { path: currentPath, offset: match.indexEnd },
      };
    }
  }
  return null;
};

export const clearEditor = (editor: Editor): void => {
  // Move the selection to encompass the whole document
  Transforms.select(editor, {
    anchor: Editor.start(editor, []),
    focus: Editor.end(editor, []),
  });

  // Remove all content within the selection
  Transforms.delete(editor);

  // Clear all marks
  const marks = Editor.marks(editor);
  if (marks) {
    for (const mark in marks) {
      Editor.removeMark(editor, mark as RTTextNodeMark);
    }
  }

  // Reset history
  (editor as any).history = { redos: [], undos: [] };
};

export const insertEmoji = (editor: Editor, unified: string): void => {
  // Insert emoji node, move selection after the node, and focus editor
  const slug = getSlugFromUnifiedId(unified);
  editor.insertNodes(
    toSlateNodes([
      new RTEmojiElementNode([new RTTextNode(slug ? `:${slug}:` : '')], {
        [RTElementProp.EMOJI__SLUG]: getSlugFromUnifiedId(unified),
        [RTElementProp.EMOJI__UNIFIED]: unified,
      }),
    ])
  );

  const selection = editor.selection;
  if (selection) {
    const after = Editor.after(editor, selection.focus);
    after && Transforms.select(editor, after);
  }
};

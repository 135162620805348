import { BaseTokenRequestHandler, FetchRequestor, GRANT_TYPE_REFRESH_TOKEN, RevokeTokenRequest } from '@openid/appauth';
import { useLocalStorage } from '@rehooks/local-storage';
import {
  ACCESS_TOKEN_STORE_KEY,
  AUTH_MANAGER_SET_ACCESS_TOKEN,
  LOGGED_IN_ACCOUNT_STORE_KEY,
  REFRESH_TOKEN_STORE_KEY,
} from '@rmvw/c-common';
import { Paths } from '@rmvw/x-common';
import * as Sentry from '@sentry/react';
import * as React from 'react';
import { Location } from 'react-router-dom';

import { IS_MOBILE_APP } from '../../env';
import Logger from '../../lib/observability/Logger';

import AuthorizationError from './AuthorizationError';
import useAuthorizationRequest from './useAuthorizationRequest';
import useAuthorizationServiceConfiguration from './useAuthorizationServiceConfiguration';
import useTokenRequest, { ITokenRequestArgs } from './useTokenRequest';

const REDIRECT_LOCATION_STORE_KEY = '_loc';

/**
 * RefreshToken state kept out of React to avoid re-renders
 */
const _refreshTokenStore = {
  get: () => window.localStorage.getItem(REFRESH_TOKEN_STORE_KEY),
  remove: () => window.localStorage.removeItem(REFRESH_TOKEN_STORE_KEY),
  set: (refreshToken: string) => window.localStorage.setItem(REFRESH_TOKEN_STORE_KEY, refreshToken),
};

interface ILoggedInAccount {
  accountId: string;
}

interface ILogoutArgs {
  /**
   * Did the user explicitly initiate the logout request?
   */
  userInitiated?: boolean;
}

interface IAuthManagerMessage {
  type: typeof AUTH_MANAGER_SET_ACCESS_TOKEN;
  accessToken?: string;
  loggedInAccount?: ILoggedInAccount;
}

interface IAuthManager {
  accessToken: string | null;
  error: AuthorizationError | null;
  getRedirectLocation: () => Location | null;
  loading: boolean;
  loggedInAccount: ILoggedInAccount | null;
  login: (args?: { loginHint?: string; refId?: string }) => Promise<void>;
  logout: (args: ILogoutArgs) => Promise<void>;
  popRedirectLocation: () => Location | null;
  pushRedirectLocation: (location: Location) => void;
  refreshAccessToken: () => Promise<boolean>;
  requestToken: (args: ITokenRequestArgs) => Promise<void>;
  setError: (error: AuthorizationError) => void;
}

export const defaultAuthContext: IAuthManager = {
  accessToken: null,
  getRedirectLocation: () => null,
  error: null,
  loading: false,
  loggedInAccount: null,
  login: async () => undefined,
  logout: async () => undefined,
  popRedirectLocation: () => null,
  pushRedirectLocation: () => undefined,
  refreshAccessToken: async () => false,
  requestToken: async () => undefined,
  setError: () => undefined,
};

export const AuthContext = React.createContext<IAuthManager>(defaultAuthContext);

export interface IAuthProviderProps {
  clientId: string;
  issuer: string;
  redirectUri: string;
}

export default function AuthProvider({
  clientId,
  issuer,
  redirectUri,
  ...props
}: React.PropsWithChildren<IAuthProviderProps>) {
  const { startAuthorizationRequest } = useAuthorizationRequest();

  const [accessToken, setAccessToken, deleteAccessToken] = useLocalStorage<string>(ACCESS_TOKEN_STORE_KEY);

  const [error, setError] = React.useState<AuthorizationError | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loggedInAccount, setLoggedInAccount, deleteLoggedInAccount] =
    useLocalStorage<ILoggedInAccount>(LOGGED_IN_ACCOUNT_STORE_KEY);

  const _cleanup = React.useCallback(() => {
    _refreshTokenStore.remove();
    deleteAccessToken();
    deleteLoggedInAccount();
    setLoading(false);
  }, [deleteAccessToken, deleteLoggedInAccount]);

  const { authConfig } = useAuthorizationServiceConfiguration({ issuer });
  const { tokenRequest } = useTokenRequest({ authConfig, clientId, issuer, redirectUri });

  const requestToken = React.useCallback(
    async (args: ITokenRequestArgs) => {
      const response = await tokenRequest(args);
      _refreshTokenStore.set(response.refreshToken);
      setAccessToken(response.accessToken);
      setLoggedInAccount({ accountId: response.accountId });
    },
    [setAccessToken, setLoggedInAccount, tokenRequest]
  );

  const REFRESH_TOKEN_LOADING_MARKER = '-=LOADING=-';

  /**
   * Attempt to refresh the current access token. Returns true if successful.
   */
  const refreshAccessToken = React.useCallback(async (): Promise<boolean> => {
    if (IS_MOBILE_APP) {
      Logger.error('[AuthProvider] refreshAccessToken unexpectedly called from c-mobile');
      return false; // Token refresh is managed by mobile app
    }

    const refreshToken = _refreshTokenStore.get();
    if (!refreshToken) {
      _cleanup();
      return false;
    }

    // If refresh token is already being refreshed, wait for it to complete.
    const MAX_REFRESH_WAIT_MS = 10_000;
    const CHECK_INTERVAL_MS = 100;
    const waitStart = Date.now();
    while (_refreshTokenStore.get() === REFRESH_TOKEN_LOADING_MARKER) {
      await new Promise((resolve) => setTimeout(resolve, CHECK_INTERVAL_MS + Math.random() * CHECK_INTERVAL_MS));
      const newRefreshToken = _refreshTokenStore.get();
      if (newRefreshToken !== REFRESH_TOKEN_LOADING_MARKER) {
        return !!newRefreshToken;
      }
      if (Date.now() - waitStart > MAX_REFRESH_WAIT_MS) {
        Logger.error('[AuthProvider] refreshAccessToken timeout');
        _cleanup();
        return false;
      }
    }

    Logger.debug('Refreshing accessToken...');

    // Temporarily mark token as LOADING
    _refreshTokenStore.set(REFRESH_TOKEN_LOADING_MARKER);

    try {
      await requestToken({ grantType: GRANT_TYPE_REFRESH_TOKEN, refreshToken }); // will reset refreshTokenRef
      return true;
    } catch (e) {
      if (e instanceof Error) {
        setError(e);
      }
      _cleanup();
      return false;
    } finally {
      setLoading(false);
    }
  }, [_cleanup, requestToken]);

  const logout = React.useCallback(
    async (args: ILogoutArgs) => {
      if (IS_MOBILE_APP) {
        window._mobileBridge?.logout?.({ suspendNotifications: args.userInitiated }); // Tokens are managed by mobile app
        return;
      }

      const refreshToken = _refreshTokenStore.get();
      if (!refreshToken) {
        return;
      }

      Logger.debug('Logging out...');

      setLoading(true);

      try {
        // Best-effort token revocation
        await new BaseTokenRequestHandler(new FetchRequestor()).performRevokeTokenRequest(
          authConfig,
          new RevokeTokenRequest({
            client_id: clientId,
            token: refreshToken,
            token_type_hint: 'refresh_token',
          })
        );
      } catch (e) {
        // swallow error (this is not critical)
        Logger.warn(e as Error, 'performRevokeTokenRequest failed');
      }

      // Cleanup
      _cleanup();

      // Redirect to home page
      if (args.userInitiated) {
        window.location.replace(Paths.HOME);
      }
    },
    [authConfig, _cleanup, clientId]
  );

  const login = React.useCallback(
    async (args?: { loginHint?: string; refId?: string }) => {
      if (accessToken || loading) {
        return;
      }

      Logger.debug('Logging in...');

      setLoading(true);

      try {
        startAuthorizationRequest({
          authConfig,
          clientId,
          loginHint: args?.loginHint,
          redirectUri,
          refId: args?.refId,
        });
      } catch (e) {
        if (e instanceof Error) {
          setError(e);
        }
        setLoading(false);
      }
    },
    [authConfig, accessToken, clientId, startAuthorizationRequest, loading, redirectUri]
  );

  /**
   * Update Sentry user when idToken changes
   */
  React.useEffect(() => {
    Sentry.setUser({ id: loggedInAccount?.accountId });
  }, [loggedInAccount]);

  /**
   * Inspect last location prior to logout
   */
  const getRedirectLocation = React.useCallback(() => {
    return JSON.parse(window.sessionStorage.getItem(REDIRECT_LOCATION_STORE_KEY) ?? 'null') as Location | null;
  }, []);

  /**
   * Save a last location prior to logout
   */
  const pushRedirectLocation = React.useCallback((location: Location) => {
    window.sessionStorage.setItem(REDIRECT_LOCATION_STORE_KEY, JSON.stringify(location));
  }, []);

  /**
   * Pop last location prior to most recent logout
   */
  const popRedirectLocation = React.useCallback(() => {
    const lastLocation = getRedirectLocation();
    window.sessionStorage.removeItem(REDIRECT_LOCATION_STORE_KEY);
    return lastLocation;
  }, [getRedirectLocation]);

  /**
   * Set up listener for accessToken updates originating from containing native app(s).
   */
  React.useEffect(() => {
    const _listener = (event: MessageEvent<IAuthManagerMessage>) => {
      if (event.origin !== window.location.origin) {
        // Security: invalid origin of message
        return;
      }

      const { data } = event;
      switch (data?.type) {
        case AUTH_MANAGER_SET_ACCESS_TOKEN: {
          if (!data.accessToken || !data.loggedInAccount || !data.loggedInAccount.accountId) {
            Logger.error(`[AuthManager] Invalid ${AUTH_MANAGER_SET_ACCESS_TOKEN} message`);
            return;
          }

          // Update externally communicated accessToken
          setAccessToken(data.accessToken);
          setLoggedInAccount(data.loggedInAccount);
          break;
        }

        default: {
          // Ignore
          return;
        }
      }
    };

    window.addEventListener('message', _listener);
    return () => window.removeEventListener('message', _listener);
  }, [setAccessToken, setLoggedInAccount]);

  const authManager = React.useMemo(
    () => ({
      accessToken: loggedInAccount && accessToken,
      error,
      getRedirectLocation,
      loading,
      loggedInAccount,
      login,
      logout,
      popRedirectLocation,
      pushRedirectLocation,
      refreshAccessToken,
      requestToken,
      setError,
    }),
    [
      accessToken,
      error,
      getRedirectLocation,
      loading,
      loggedInAccount,
      login,
      logout,
      popRedirectLocation,
      pushRedirectLocation,
      refreshAccessToken,
      requestToken,
    ]
  );

  return <AuthContext.Provider value={authManager}>{props.children}</AuthContext.Provider>;
}

export const useAuthManager = () => React.useContext(AuthContext);

import * as React from 'react';
import { useTheme } from 'styled-components';

import { useMarkThreadRead } from '../../hooks/useMarkRead';
import { copyText } from '../../lib/copyText';
import { CanonicalSizePx } from '../../providers/ThemeProvider/themes';
import Dropdown from '../Dropdown';
import DropdownButton, { IDropdownButtonProps } from '../DropdownButton';
import useFollowControl from '../FollowControl/useFollowControl';
import {
  BookmarkIcon,
  BookmarkRemoveIcon,
  CameraIcon,
  DotsHorizontalIcon,
  LinkIcon,
  MailIcon,
  MailOpenIcon,
  NotificationIcon,
  NotificationOffIcon,
} from '../Icon';
import { IShortcutTarget, useAddShortcutMutation, useDeleteShortcutMutation, useGetShortcutForTarget } from '../NavBar';

export async function copyPermalink(path: string) {
  await copyText(`${window.location.origin}${path}`);
}

interface ICopyLinkItemProps {
  icon?: JSX.Element;
  path: string;
}

export function CopyLinkItem(props: React.PropsWithChildren<ICopyLinkItemProps>) {
  const theme = useTheme();

  return (
    <Dropdown.Item onClick={() => copyPermalink(props.path)}>
      {props.icon ?? <LinkIcon color={theme.color.secondaryColor} size={CanonicalSizePx.xxSmall} />}
      {props.children ?? <>Copy link</>}
    </Dropdown.Item>
  );
}

interface IMarkThreadReadItemProps {
  hasLastEvent: boolean;
  hasUnread: boolean;
  threadId: string;
}

export function MarkThreadReadItem({ hasLastEvent, hasUnread, threadId }: IMarkThreadReadItemProps) {
  const theme = useTheme();
  const { markThreadRead, markThreadUnread } = useMarkThreadRead(threadId);

  // if no unreads -- don't show the mark read option
  // if no last event -- there is nothing to be marked unread, so don't shore mark unread option
  if (hasUnread) {
    return (
      <Dropdown.Item onClick={markThreadRead}>
        <MailOpenIcon color={theme.color.secondaryColor} size={CanonicalSizePx.xxSmall} />
        Mark read
      </Dropdown.Item>
    );
  } else if (hasLastEvent) {
    return (
      <Dropdown.Item onClick={markThreadUnread}>
        <MailIcon color={theme.color.secondaryColor} size={CanonicalSizePx.xxSmall} />
        Mark unread
      </Dropdown.Item>
    );
  } else {
    return null;
  }
}

export function FollowThreadItem({ following, threadId }: { following: boolean; threadId: string }) {
  const theme = useTheme();
  const { setFollowing } = useFollowControl(threadId);

  return (
    <Dropdown.Item onClick={() => setFollowing(!following)}>
      {following ? (
        <NotificationOffIcon color={theme.color.secondaryColor} size={CanonicalSizePx.xxSmall} />
      ) : (
        <NotificationIcon color={theme.color.secondaryColor} size={CanonicalSizePx.xxSmall} />
      )}
      {following ? 'Unsubscribe' : 'Subscribe'}
    </Dropdown.Item>
  );
}

interface IStartVideoChatItemProps {
  isVideoChatActive: boolean;
  onClick: () => void;
}

export function StartVideoChatItem({ isVideoChatActive, onClick }: IStartVideoChatItemProps) {
  const theme = useTheme();

  return (
    <Dropdown.Item onClick={onClick}>
      <CameraIcon color={theme.color.secondaryColor} size={CanonicalSizePx.xxSmall} />
      {isVideoChatActive ? 'Join video chat' : 'Start video chat'}
    </Dropdown.Item>
  );
}

interface IBookmarkThreadItemProps {
  shortcutTarget: IShortcutTarget;
}

export function ShortcutThreadItem({ shortcutTarget }: IBookmarkThreadItemProps) {
  const theme = useTheme();
  const shortcut = useGetShortcutForTarget(shortcutTarget.id);
  const [addShortcut, _addShortcutResult] = useAddShortcutMutation();
  const [removeShortcut, _removeShortcutResult] = useDeleteShortcutMutation();

  return (
    <Dropdown.Item
      onClick={async () => {
        if (shortcut) {
          await removeShortcut(shortcut.id);
        } else {
          await addShortcut(shortcutTarget);
        }
      }}
    >
      {shortcut ? (
        <BookmarkRemoveIcon color={theme.color.secondaryColor} size={CanonicalSizePx.xxSmall} />
      ) : (
        <BookmarkIcon color={theme.color.secondaryColor} size={CanonicalSizePx.xxSmall} />
      )}
      {shortcut ? 'Remove shortcut' : 'Add shortcut'}
    </Dropdown.Item>
  );
}

export type IActionsDropdownProps = IDropdownButtonProps;

function ActionsDropdown({ color, children, icon, title, ...props }: IActionsDropdownProps) {
  const size = props.size ?? 'medium';
  return (
    <DropdownButton
      {...props}
      hideCaret
      icon={icon ?? <DotsHorizontalIcon color={color} />}
      shape="circle"
      size={size}
      title={title}
      tooltip={title}
    >
      {children}
    </DropdownButton>
  );
}

export default ActionsDropdown;

import * as React from 'react';
import styled from 'styled-components';

import { TypeaheadProfileQueryProfileType } from '../../../___generated___/globalTypes';
import { useLoggedInAccount } from '../../../hooks/useLoggedInAccount';
import { useGetPrivateChatQuery } from '../../EditParentModal/hooks';
import ProfileNameTypeahead from '../ProfileNameTypeahead';
import { useProfileNameTypeaheadOptionsByIdQuery } from '../ProfileNameTypeahead/useProfileNameTypeaheadOptionsQuery';
import Typeahead from '../Typeahead';

const _Container = styled.div`
  align-items: center;
  display: flex;
`;

export const OriginToken = styled(Typeahead.Token)`
  background-color: ${({ theme }) => theme.color.background200};
  color: ${({ theme }) => theme.color.color};
`;

const _ProfileNameTypeahead = styled(ProfileNameTypeahead)`
  flex: 1;
`;

export interface IOriginTypeaheadProps {
  disabled: boolean;
  excludeIds?: string[];
  onRemove: () => void;
  onSelect: (originId: string, originName: string) => void;
  placeholder?: string;
  selectedId?: string | null;
}

function OriginTypeahead({ disabled, excludeIds, onRemove, onSelect, placeholder, selectedId }: IOriginTypeaheadProps) {
  const loggedInAccount = useLoggedInAccount();
  const [getPrivateChat] = useGetPrivateChatQuery();

  async function fetchPrivateChatIdFromAccountId(accountId: string) {
    const result = await getPrivateChat(accountId);
    return result?.data?.account?.privateChat?.id ?? null;
  }

  const selectedDiscussion =
    useProfileNameTypeaheadOptionsByIdQuery(selectedId ? [selectedId] : []).options?.[0] ?? null;

  const _excludeIds = excludeIds ? [...excludeIds, loggedInAccount?.id] : [loggedInAccount?.id];

  return (
    <>
      {selectedDiscussion ? (
        <_Container>
          <OriginToken disabled={disabled} onRemove={onRemove} option={{}}>
            {selectedDiscussion.name}
          </OriginToken>
        </_Container>
      ) : (
        <_Container>
          <_ProfileNameTypeahead
            id="edit-origin-typeahead"
            autoFocus={true}
            disabled={disabled}
            enableNullQuery={true}
            excludeIds={_excludeIds.filterNullish()}
            flattenResults={true}
            isLoading={false}
            minLength={0}
            multiple={false}
            onChange={async (selected) => {
              if (selected[0]) {
                const id =
                  selected[0].__typename === 'Account'
                    ? await fetchPrivateChatIdFromAccountId(selected[0].id)
                    : selected[0].id;

                if (id) {
                  const name = selected[0]?.name;
                  await onSelect(id, name);
                }
              }
            }}
            placeholder={placeholder ?? 'Select a parent Team, Discussion, or Private Chat'}
            profileTypes={[
              TypeaheadProfileQueryProfileType.ACCOUNT,
              TypeaheadProfileQueryProfileType.DISCUSSION,
              TypeaheadProfileQueryProfileType.TEAM,
            ]}
            selected={selectedDiscussion ? [selectedDiscussion] : undefined}
          />
        </_Container>
      )}
    </>
  );
}

export default OriginTypeahead;
